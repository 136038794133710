import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { initialState } from './initialState';
import { get, min, max, map, isEmpty, find, orderBy } from 'lodash';
import * as d3 from 'd3';
import * as constants from './constants';
import { serializeTableData, serializeTableRowData } from 'ui-component/tables/serialization';
import { ALL_TABLE_COLUMNS } from 'pages/drilling-completion/DrillingDesign/schema/table_columns';
import { SCHEMA_SENS_HD_ANNULAR_VELOCITY } from 'pages/drilling-completion/DrillingDesign/schema/table/sens_hd_annular_velocity';
import { SCHEMA_SENS_HD_HYDRAULIC } from 'pages/drilling-completion/DrillingDesign/schema/table/sens_hd_hydraulic';
import { SCHEMA_SENS_DT_DNT } from 'pages/drilling-completion/DrillingDesign/schema/table/sens_dt_dnt';
import { SCHEMA_SENS_DT_SUMMARY } from 'pages/drilling-completion/DrillingDesign/schema/table/sens_dt_summary';

const SCHEMA_CD_CASING_ENVELOPE_SENS = ALL_TABLE_COLUMNS.filter((d) => d.table_id === 'cd_casing_envelope_sens' && d.ui_show);
const SCHEMA_CD_BC_SENS = ALL_TABLE_COLUMNS.filter((d) => d.table_id === 'cd_burst_collapse_sens' && d.ui_show);

// CURRENT STORE SELECTORS
// ==================================================================================================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const currentLayoutSelector = (store) => getStore(store).currentLayout;

// Project
export const projectIdsSelector = (store) => getStore(store).projectIds;
export const projectsByIdSelector = (store) => getStore(store).projectsById;
export const selectedProjectIdsSelector = (store) => getStore(store).selectedProjectIds;

// Project Input
export const inputGeneralByProjectIdSelector = (store) => getStore(store).inputGeneralByProjectId;
export const inputPressureByProjectIdSelector = (store) => getStore(store).inputPressureByProjectId;
export const inputDrillingProgramByProjectIdSelector = (store) => getStore(store).inputDrillingProgramByProjectId;

// Project Output
export const outputCasingDesignByProjectIdSelector = (store) => getStore(store).outputCasingDesignByProjectId;
export const outputDragTorqueByProjectIdSelector = (store) => getStore(store).outputDragTorqueByProjectId;
export const outputHydraulicByProjectIdSelector = (store) => getStore(store).outputHydraulicByProjectId;

export const selectedCasingIdsSelector = (store) => getStore(store).selectedCasingIds;
export const sensitivityIdsSelector = (store) => getStore(store).sensitivityIds;
export const sensitivitiesByIdSelector = (store) => getStore(store).sensitivitiesById;
export const hdSensitivitiesByIdSelector = (store) => getStore(store).hdSensitivitiesById;
export const cdSensitivitiesByIdSelector = (store) => getStore(store).cdSensitivitiesById;
export const dtSensitivitiesByIdSelector = (store) => getStore(store).dtSensitivitiesById;
export const selectedSensitivityIdsSelector = (store) => getStore(store).selectedSensitivityIds;
export const selectedHdScenarioIdsSelector = (store) => getStore(store).selectedHdScenarioIds;
export const selectedCdScenarioIdsSelector = (store) => getStore(store).selectedCdScenarioIds;
export const selectedDtScenarioIdsSelector = (store) => getStore(store).selectedDtScenarioIds;
export const settingsSelector = (store) => getStore(store).settings;
export const projectsSelector = createSelector(projectIdsSelector, projectsByIdSelector, (ids, byId) => ids.map((id) => byId[id]));

// CURRENT PROJECT SELECTORS
// ==================================================================================================

export const currentProjectIdSelector = createSelector(selectedProjectIdsSelector, (selectedIds) => {
  if (selectedIds.length > 0) {
    return selectedIds[0];
  }
  return null;
});
export const currentCasingIdSelector = createSelector(selectedCasingIdsSelector, (selectedIds) => {
  if (selectedIds.length > 0) {
    return selectedIds[0];
  }
  return null;
});
// Note: this will limit how many case data will be rendered
export const renderProjectIdsSelector = createSelector(selectedProjectIdsSelector, (selectedProjectIds) => selectedProjectIds.slice(0, 10));
export const currentProjectSelector = createSelector(projectsByIdSelector, currentProjectIdSelector, (byId, id) => byId[id] || {});
export const currentGeneralSelector = createSelector(
  inputGeneralByProjectIdSelector,
  currentProjectIdSelector,
  (byId, id) => byId[id] || {}
);
export const currentPressureSelector = createSelector(
  inputPressureByProjectIdSelector,
  currentProjectIdSelector,
  (byId, id) => byId[id] || {}
);
export const currentDrillingProgramSelector = createSelector(
  inputDrillingProgramByProjectIdSelector,
  currentProjectIdSelector,
  (byId, id) => byId[id] || {}
);

// UI Dropdown
// ==================================================================================================

export const currentCasingOptionSelector = createSelector(currentDrillingProgramSelector, (drillingProgram) => {
  const casingPrograms = get(drillingProgram, 'casing_program', []);
  const casingOption = [];
  casingPrograms.forEach((casingProgram) => {
    const casingId = get(casingProgram, 'id', 'UNKNOWN');
    const casingType = get(casingProgram, 'casing_type', 'UNKNOWN');
    const setDepth = get(casingProgram, 'set_depth', null);
    const setDepthUom = get(casingProgram, 'set_depth_uom', null);
    const label = `${casingType} - ${setDepth} ${setDepthUom}`;
    casingOption.push({
      id: casingId,
      label
    });
  });
  return casingOption;
});

// CURRENT CASING DESIGN
// ==================================================================================================

export const currentCasingDesignSelector = createSelector(
  outputCasingDesignByProjectIdSelector,
  currentProjectIdSelector,
  (byId, id) => byId[id] || {}
);

export const currentSerializedCasingDesignSelector = createSelector(currentCasingDesignSelector, (casingDesign) => {
  const casingDesignById = get(casingDesign, 'casing_design_by_id', {});
  const casingIds = Object.keys(casingDesignById);
  const serializedData = {};
  casingIds.forEach((id) => {
    const casingDesign = casingDesignById[id];
    const { df_bc: dfBC, df_envelope: dfEnvelope } = casingDesign;
    serializedData[id] = {
      bcTbl: serializeTableRowData(dfBC),
      enTbl: serializeTableRowData(dfEnvelope)
    };
  });
  return serializedData;
});

// CURRENT DRAG TORQUE
// ==================================================================================================

export const currentDragTorqueSelector = createSelector(
  outputDragTorqueByProjectIdSelector,
  currentProjectIdSelector,
  (byId, id) => byId[id] || {}
);

export const currentDragTorqueUomSelector = createSelector(currentDragTorqueSelector, (dragTorqueOutput) =>
  get(dragTorqueOutput, 'drag_torque_uom', {})
);

export const currentSerializedDragTorqueSelector = createSelector(currentDragTorqueSelector, (dragTorqueOutput) => {
  const dragTorqueById = get(dragTorqueOutput, 'drag_torque_by_id', {});
  const casingIds = Object.keys(dragTorqueById);
  const serializedData = {};
  casingIds.forEach((id) => {
    const dfPipe = dragTorqueById[id].drill_pipe;
    const dfCasing = dragTorqueById[id].casing;
    serializedData[id] = {
      drillPipe: serializeTableRowData(dfPipe),
      casing: serializeTableRowData(dfCasing),
      maxWOB: get(dragTorqueById, `${id}.max_WOB`, null),
      WOB: get(dragTorqueById, `${id}.WOB`, null),
      dcLength: get(dragTorqueById, `${id}.dc_length`, null),
      neutralMd: get(dragTorqueById, `${id}.neutral_md`, null),
      neutralLength: get(dragTorqueById, `${id}.neutral_length`, null)
    };
  });
  return serializedData;
});

// CURRENT DRAG TORQUE
// ==================================================================================================

export const currentHydraulicSelector = createSelector(
  outputHydraulicByProjectIdSelector,
  currentProjectIdSelector,
  (byId, id) => byId[id] || {}
);

export const currentSerializedHydraulicSelector = createSelector(currentHydraulicSelector, (obj) => {
  const serializedData = {
    summaryUom: get(obj, `summary_uom`, null),
    hydraulicUom: get(obj, `hydraulic_uom`, null),
    vAnnularUom: get(obj, `v_annular_uom`, null),
    summaryData: serializeTableRowData(get(obj, `df_summary`, {})),
    hydraulicData: serializeTableRowData(get(obj, `df_hydraulic`, {})),
    vAnnularData: serializeTableRowData(get(obj, `df_v_annular`, {}))
  };
  return serializedData;
});

export const currentBaseMudProgramSelector = createSelector(
  currentCasingIdSelector,
  currentDrillingProgramSelector,
  (casingId, drillProgram) => {
    /*
  Create a base case Mud Program for sensitivity analysis (mud program at shoe depth)
  */
    const mudProgram = get(drillProgram, 'mud_program', []);
    const muds = mudProgram.filter((m) => m.casing_id === casingId);
    const sortedMuds = orderBy(muds, ['drilled_depth'], ['asc']);
    return sortedMuds[sortedMuds.length - 1];
  }
);

export const surveyDataSelector = createSelector(currentPressureSelector, (obj) => get(obj, 'p_tbl', []));

export const currentMethodSelector = createSelector(currentPressureSelector, (obj) => get(obj, 'directional_survey_method', undefined));

export const colorAttrSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'survey_chart.color_attr', null));

export const colorStopsSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'survey_chart.color_stops', null));

export const colorAttrMinSelector = createSelector(colorAttrSelector, surveyDataSelector, (colorAttr, rowData) => {
  const attrMin = min(rowData.map((d) => d[colorAttr]));
  return attrMin;
});

export const colorAttrMaxSelector = createSelector(colorAttrSelector, surveyDataSelector, (colorAttr, rowData) => {
  const attrMax = max(rowData.map((d) => d[colorAttr]));
  return attrMax;
});

export const currentCasingSelector = createSelector(
  currentCasingIdSelector,
  currentDrillingProgramSelector,
  (casingId, drillingProgram) => {
    /*
  Create a base case casing for sensitivity analysis
  */
    const casingProgram = get(drillingProgram, 'casing_program', []);
    const casings = casingProgram.filter((m) => m.id === casingId);
    const casing = get(casings, '[0].casing', null);
    return casing;
  }
);

// COMPARE CASES SELECTOR
// ==========================

export const dataByProjectIdSelector = createCachedSelector(
  projectsByIdSelector,
  inputPressureByProjectIdSelector,
  (state, projectId) => projectId,
  (projectsById, inputPressureByProjectId, projectId) => {
    // Survey Data
    const rowData = get(inputPressureByProjectId, `${projectId}.p_tbl`, []);
    const uom = get(inputPressureByProjectId, `${projectId}.p_tbl_uom`, []);
    const colorAttr = get(projectsById, `${projectId}.survey_chart.color_attr`, 'z');
    const colorStops = get(projectsById, `${projectId}.survey_chart.color_stops`, null);
    const name = get(projectsById, `${projectId}.name`, null);

    // Chart Data
    const colorAttrData = rowData.map((d) => d[colorAttr]);
    const x = rowData.map((d) => d.x);
    const y = rowData.map((d) => d.y);
    const z = rowData.map((d) => d.z);
    const minColorAttr = min(colorAttrData);
    const maxColorAttr = max(colorAttrData);
    const colorData = [];

    if (!isEmpty(colorStops)) {
      const offsets = map(colorStops, 'offset');
      const colors = map(colorStops, 'color');
      const colorScale = d3.scale.linear().domain(offsets).range(colors).interpolate(d3.interpolateRgb);
      colorAttrData.forEach((value) => {
        let color = '#0000ff';
        if (maxColorAttr > minColorAttr && colorScale) {
          const offset = (value - minColorAttr) / (maxColorAttr - minColorAttr);
          color = colorScale(offset);
        }
        colorData.push(color);
      });
    }

    return {
      rowData,
      x,
      y,
      z,
      maxColorAttr,
      minColorAttr,
      name,
      colorData,
      uom
    };
  }
)(
  (state, projectId) => projectId // Cache selectors by projectId
);

// CURRENT SENSITIVITY SELECTORS
// ==========================

export const currentSensitivityIdSelector = createSelector(selectedSensitivityIdsSelector, (selectedIds) => {
  if (selectedIds.length > 0) {
    return selectedIds[0];
  }
  return null;
});

export const currentSensitivitySelector = createSelector(
  sensitivitiesByIdSelector,
  currentSensitivityIdSelector,
  (byId, id) => byId[id] || undefined
);

export const currentHSensitivitySelector = createSelector(
  hdSensitivitiesByIdSelector,
  currentSensitivityIdSelector,
  (byId, id) => byId[id] || undefined
);

export const currentCdSensitivitySelector = createSelector(
  cdSensitivitiesByIdSelector,
  currentSensitivityIdSelector,
  (byId, id) => byId[id] || undefined
);

export const currentDtSensitivitySelector = createSelector(
  dtSensitivitiesByIdSelector,
  currentSensitivityIdSelector,
  (byId, id) => byId[id] || undefined
);

export const sensitivitiesSelector = createSelector(sensitivityIdsSelector, sensitivitiesByIdSelector, (ids, byId) =>
  ids.map((id) => byId[id])
);

export const sensitivitiesBySelectedCasingIdSelector = createSelector(
  sensitivitiesSelector,
  selectedCasingIdsSelector,
  (sensitivities, selectedCasingIds) => sensitivities.filter((sens) => selectedCasingIds.includes(sens.casing_id))
);

export const currentHScenarioSelector = createSelector(currentHSensitivitySelector, (sensitivity) => {
  const df = {
    columns: get(sensitivity, 'scenario_cols', []),
    data: get(sensitivity, 'scenario_data', []),
    schema: get(sensitivity, 'scenario_schema', [])
  };
  return serializeTableData(df);
});

export const currentHAnnularSelector = createSelector(currentHSensitivitySelector, (sensitivity) => {
  const df = {
    columns: get(sensitivity, 'v_annular_cols', []),
    data: get(sensitivity, 'v_annular_data_uom', []),
    schema: SCHEMA_SENS_HD_ANNULAR_VELOCITY
  };
  return serializeTableData(df);
});

export const currentHHydraulicSelector = createSelector(currentHSensitivitySelector, (sensitivity) => {
  const df = {
    columns: get(sensitivity, 'hydraulic_cols', []),
    data: get(sensitivity, 'hydraulic_data_uom', []),
    schema: SCHEMA_SENS_HD_HYDRAULIC
  };
  return serializeTableData(df);
});

export const currentSensitivitySummarySelector = createSelector(
  currentHScenarioSelector,
  currentHHydraulicSelector,
  (scenario, hydraulic) => {
    const scenarioData = get(scenario, 'rowData', []);
    const hydraulicData = get(hydraulic, 'rowData', []);
    const scenarioColDefs = get(scenario, 'columnDefs', []);
    const hydraulicColDefs = get(hydraulic, 'columnDefs', []);
    const scenarioPinnedData = get(scenario, 'pinnedTopRowData', []);
    const hydraulicPinnedData = get(hydraulic, 'pinnedTopRowData', []);
    const rowById = {};
    scenarioData.forEach((r) => {
      const rowId = `id-${r.scenario}`;
      rowById[rowId] = {
        param_1: r.param_1,
        param_2: r.param_2,
        param_3: r.param_3
      };
    });
    const summaryData = hydraulicData.map((r) => {
      const rowId = `id-${r.scenario}`;
      const row = rowById[rowId];
      return {
        ...r,
        ...row
      };
    });

    let columnDefs = [];

    if (scenarioColDefs.length > 0) {
      columnDefs = [...hydraulicColDefs, scenarioColDefs[1], scenarioColDefs[2], scenarioColDefs[3]];
    }

    return {
      columnDefs,
      rowData: summaryData,
      pinnedTopRowData: [{ ...scenarioPinnedData[0], ...hydraulicPinnedData[0] }]
    };
  }
);

export const currentCdScenarioSelector = createSelector(currentCdSensitivitySelector, (sensitivity) => {
  const df = {
    columns: get(sensitivity, 'scenario_cols', []),
    data: get(sensitivity, 'scenario_data', []),
    schema: get(sensitivity, 'scenario_schema', [])
  };
  return serializeTableData(df);
});

export const currentCdEnvelopeSelector = createSelector(currentCdSensitivitySelector, (sensitivity) => {
  const df = {
    columns: get(sensitivity, 'envelope_cols', []),
    data: get(sensitivity, 'envelope_data_uom', []),
    schema: SCHEMA_CD_CASING_ENVELOPE_SENS
  };
  return serializeTableData(df);
});

export const currentCdBurstCollapseSelector = createSelector(currentCdSensitivitySelector, (sensitivity) => {
  const df = {
    columns: get(sensitivity, 'bc_cols', []),
    data: get(sensitivity, 'bc_data_uom', []),
    schema: SCHEMA_CD_BC_SENS
  };
  return serializeTableData(df);
});

export const currentDTScenarioSelector = createSelector(currentDtSensitivitySelector, (sensitivity) => {
  const df = {
    columns: get(sensitivity, 'scenario_cols', []),
    data: get(sensitivity, 'scenario_data', []),
    schema: get(sensitivity, 'scenario_schema', [])
  };
  return serializeTableData(df);
});

export const currentDtDragTorqueSelector = createSelector(currentDtSensitivitySelector, (sensitivity) => {
  const uom = get(sensitivity, 'drag_torque_uom', {});
  const schema = SCHEMA_SENS_DT_DNT.map((col) => {
    const colId = get(col, 'col_id', null);
    const newCol = { ...col };
    if (!isEmpty(colId)) {
      newCol[colId] = get(uom, `${colId}_uom`, '-');
    }
    return newCol;
  });
  const df = {
    columns: get(sensitivity, 'drag_torque_cols', []),
    data: get(sensitivity, 'drag_torque_data_uom', []),
    schema
  };
  return serializeTableData(df);
});

export const currentDtSummarySelector = createSelector(currentDtSensitivitySelector, (sensitivity) => {
  const uom = get(sensitivity, 'summary_uom', {});
  const schema = SCHEMA_SENS_DT_SUMMARY.map((col) => {
    const colId = get(col, 'col_id', null);
    const newCol = { ...col };
    if (!isEmpty(colId)) {
      newCol[colId] = get(uom, `${colId}_uom`, '-');
    }
    return newCol;
  });
  const df = {
    columns: get(sensitivity, 'summary_cols', []),
    data: get(sensitivity, 'summary_data_uom', []),
    schema
  };
  return serializeTableData(df);
});

export const currentDTDrillPipeSelector = createSelector(currentDtSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_dnt_drill_pipe', {});
  return serializeTableData(df);
});

export const currentDTCasingSelector = createSelector(currentDtSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_dnt_casing', {});
  return serializeTableData(df);
});

// Unuse
export const currentHydraulicProgramSelector = createSelector(currentProjectSelector, (currentProject) => {
  // const casingPrograms = get(currentProject, 'casing_program', []);
  const mudPrograms = get(currentProject, 'mud_program', []);
  const hydraulicByMudId = get(currentProject, 'hydraulic_by_mud_id', {});

  const hydraulicProgram = [];
  mudPrograms.forEach((mud) => {
    const mudId = get(mud, 'id', null);
    // const casingId = get(mud, 'casing_id', null);
    const oneRow = {
      drilled_depth: get(mud, 'drilled_depth', null),
      q: get(mud, 'q', null),
      den: get(mud, 'den', null),
      vis: get(mud, 'vis', null),
      fluid_model: get(mud, 'fluid_model', null),
      ty: get(mud, 'ty', null),
      K: get(mud, 'K', null),
      n: get(mud, 'n', null),
      drilled_tvd: get(hydraulicByMudId, `${mudId}.drilled_tvd`, null),
      V_kick: get(hydraulicByMudId, `${mudId}.V_kick`, null),
      h_kick: get(hydraulicByMudId, `${mudId}.h_kick`, null),
      P_surf: get(hydraulicByMudId, `${mudId}.P_surf`, null)
    };
    hydraulicProgram.push(oneRow);
  });

  const columns = [
    {
      field: 'drilled_depth',
      headerName: 'Drilled Depth (ft)'
    },
    {
      field: 'q',
      headerName: 'Flow Rate (gpm)'
    },
    {
      field: 'den',
      headerName: 'Fluid Density (ppg)'
    },
    {
      field: 'vis',
      headerName: 'Fluid Viscosity (cp)'
    },
    {
      field: 'fluid_model',
      headerName: 'Fluid Model'
    },
    {
      field: 'ty',
      headerName: 'Yield ty'
    },
    {
      field: 'K',
      headerName: 'K'
    },
    {
      field: 'n',
      headerName: 'n'
    },
    {
      field: 'drilled_tvd',
      headerName: 'Drilled TVD (ft)'
    },
    {
      field: 'V_kick',
      headerName: 'Kick Volume'
    },
    {
      field: 'h_kick',
      headerName: 'h_kick'
    },
    {
      field: 'P_surf',
      headerName: 'P_surf'
    }
  ];

  const columnDefs = columns.map((d) => ({
    field: d.field,
    headerName: d.headerName,
    tooltipField: d.field,
    headerTooltip: d.headerName,
    minWidth: 80
  }));

  return {
    columnDefs,
    rowData: hydraulicProgram
  };
});
